import dayjs from 'dayjs'
import Link from 'next/link'
import styles from './ArticleCard.module.scss'
import { Article } from '../types'
import Tag from 'components/tag/Tag'
import { useTranslation } from 'next-i18next'
import { IconExternal } from 'assets/svg'
import Img from 'components/img/Img'
import classNames from 'classnames'
import { CmsArticleTypes } from 'types/cms/enums'

const ArticleCard = ({ author, category, destination, extraClassNames, publishedAt, thumbnail, title, type }: Article) => {
  // i18n
  const { t } = useTranslation('common')

  return (
    <div>
      {type !== CmsArticleTypes.BLOG_EXTERNAL ? (
        <Link href={destination} className={classNames(styles['article-card'], extraClassNames)}>
          <figure className={styles['thumbnail-wrapper']}>
            {thumbnail && (
              <Img
                fill
                publicId={thumbnail.data.attributes.provider_metadata.public_id}
                alt={`${t('thumbnail', 'thumbnail')} ${title}`}
                className={styles.thumbnail}
                sizes="(max-width: 620px) 100vw, (max-width: 920px) 50vw, (max-width: 1120px) 33vw, 25vw"
              />
            )}
          </figure>
          <div className={styles.content}>
            {category && (
              <Tag transparentBackground color={category?.color}>
                {category?.title}
              </Tag>
            )}

            <h4 className={classNames('h6', styles.title)}>{title}</h4>

            <small className={styles.author}>
              {dayjs(publishedAt).format('DD/MM/YYYY')} | {author.firstName}
            </small>
          </div>
        </Link>
      ) : (
        <a className={classNames(styles['article-card'], extraClassNames)} href={destination} target="_blank" rel="noreferrer noopener">
          {thumbnail && (
            <figure className={styles['thumbnail-wrapper']}>
              <Img
                fill
                publicId={thumbnail.data.attributes.provider_metadata.public_id}
                alt={`${t('thumbnail', 'thumbnail')} ${title}`}
                className={styles.thumbnail}
                sizes="(max-width: 620px) 100vw, (max-width: 920px) 50vw, (max-width: 1120px) 33vw, 25vw"
              />
            </figure>
          )}
          <div className={styles.content}>
            {category && (
              <Tag transparentBackground color={category?.color}>
                {category?.title}
              </Tag>
            )}

            <h4 className={classNames('h6', styles.title)}>
              {title} <IconExternal />
            </h4>

            <small className={styles.author}>
              {dayjs(publishedAt).format('DD/MM/YYYY')} | {author.firstName}
            </small>
          </div>
        </a>
      )}
    </div>
  )
}

ArticleCard.defaultProps = {
  type: CmsArticleTypes.BLOG_INTERNAL
}

export default ArticleCard
